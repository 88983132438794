*{
  padding: 0;
  margin:0;
  box-sizing: border-box;
}
body, html { overflow-x: hidden !important;}
.lazyload-wrapper {
  display: initial;
}
a,a:hover{text-decoration: none;color:inherit}
span.bounce1 img { width: 22px; }
.OnlineR a { color: #fff; }
a.parantlogo { padding: 2px 10px; }
.OnlineR { position: fixed; left: 0; z-index: 99; text-align: center; color: #fff; top: 40%; font-family: Open Sans, sans-serif; }
@keyframes bounce1 { 
  0% {
    background-color: #163f65;
} 
33% {
    background-color: #2666a0;
}
66% {
    background-color: #143b60;
}
100% {
    background-color: #2666a0;
}
}

.contact-items {
  background: #ffffff;
  box-shadow: 1px 4px 20px -2px rgba(0,0,0,0.1);
  padding: 50px;
  position: relative;
}

  .contact-items .left-item .item {
      display: flex;
  }

  .contact-items .left-item i {
      display: inline-block;
      font-size: 32px;
      color: #ef8f00;
      margin-right: 25px;
  }

  .contact-items .left-item .item .info h5 {
      font-weight: 600;
      font-size: 18px;
      color: black;
  }

  .contact-items .left-item .item {
      margin-top: 30px;
  }

      .contact-items .left-item .item:first-child {
          margin-top: 0;
      }

      .contact-items .left-item .item .info p {
          margin: 0;
      }

  .contact-items .right-item {
      padding-left: 35px;
      border-left: 1px solid #e7e7e7;
  }

     







.bounce1 { border-radius: 0px 25px 25px 0px; color: #fff; font-size: 14px; font-weight: 700; text-align: center; animation: bounce1 1s infinite; padding: 10px 12px; }

.navbar .nav-item:hover .dropdown-menu li {
  padding: 0;
}
.nav-link {
 
  color: #fff;
}
 
.navbar .nav-item .dropdown-menu li a {
  padding: 10px 15px;
  border-bottom: 1px solid #c7a65a;
  color: #fff;
  display: block;
}
.navbar .nav-item .dropdown-menu {
  background: var(--maroon);
  min-width: max-content;
  border-radius: 3px;
}

.navbar .nav-item .dropdown-menu li a:hover {
  background: #19456f;
}
.navbar .nav-item .dropdown-menu {
  border: 0px solid #ccc;
  background:#19456f;
}
.navbar .nav-item .dropdown-menu li ,.navbar .nav-item .dropdown-menu li:last-child a {
  border-bottom: 0px solid #fff;
}
button.navbar-toggler:focus {
  box-shadow: none;
}
.navbar .dropdown-item.active, .navbar .dropdown-item:active {
  background:#19456f;
}
span.navbar-toggler-icon {
  background-image: none;
}

.navbar .nav-item:hover .nav-link , .navbar .nav-link:focus{
  color: var(--yellow) !important;
}
.highlights {width: 80%;display: flex;flex-direction: row;justify-content: center;align-items: center;background:linear-gradient(178deg, #2869a5, #103252)}
.highlights p a {color: #fff;line-height: 30px;font-size: 15px;padding-right: 5px;margin-left: 10px;}
.highlights p {
   margin-bottom: 0;
   color:#fff;
}
.topheader {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.topheader p {
  margin-bottom: 0;
}
.topheaderleft , .topheaderright{
  background: linear-gradient(173deg, #ffb800, #ef8f00);
  width: 10%;
}
.headerbottom li a:last-child:after {
  content: ' ';
}
p {
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 300;
}
h1,h2,h3,h4,h5,h6{
  font-family: "Domine", serif;
}
.topheaderleft p {
  line-height: 30px;
  font-size: 16px;
  text-align: center;
}

.topheaderright img {
  width: 18px;
  height: 18px;
  margin-right: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: center;
}

.topheaderright {
  text-align: center;
  padding: 1px 0px;
}
.midheader {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 20px;
  padding-right: 110px;
}

.midheadermid {
  display: flex;
}

.midheadermid p {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 0;
}

button {
  border: none;
  background: linear-gradient(178deg, #2869a5, #103252);
  color: #fff;
  padding: 10px 15px;
  border-radius: 15px;
}
.midheaderright p {padding-right: 10px;margin-bottom: 0;position: relative;}
.midheaderright button {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 2s blinker infinite alternate;
}
.midheaderright p:after {
    position: absolute;
    border-left: 1px solid #ffffff38;
    content: ' ';
    width: 100%;
    height: 100%;
    margin-left: 5px;
}

.headerbottom {
  position: relative;
  z-index: 9;
  border-radius: 5px;
  display: flex;
  background: linear-gradient(178deg, #2869a5, #103252);
  padding-top: 5px;
  padding-bottom: 5px;
  width: 65%;
  margin-left: auto;
  margin-right: 110px;
  padding-left: 30px;
}
.midheaderleft {
  width: 30%;
}
.navbar-nav li {
  position: relative;
  text-transform: uppercase;
  color: #fff;
  padding-right: 15px;
font-size: 14px;
}
.navbar-nav li a {
list-style: none;
color: #fff;
text-transform: uppercase;
position: relative;
padding-right: 15px;
}
.navbar-nav li:after {
  position: absolute;
  content: ' | ';
  margin-left: 0;
  height: 100%;
  color: #ffffff91;
  transform: scale(1.2);
  top: 9px;
  left: -10px;
}
.navbar-nav li:first-child:after {
 
  content: ' ';
}
.navbar-nav  ul.dropdown-menu li:after{

  content: '  ';
}
.slider-sec {
  margin-top: -25px;
position: relative;
}

.slider-info {
  position: absolute;
  bottom:50px;
  width: 22%;
  left: 30px;
  background: url('https://webapi.entab.info/api/image/STMST/public/Images/sliderinfobg.png') no-repeat !important; 
  padding: 30px;
  color: #fff;
}
.kindergarden{
  position: absolute;
  bottom: 30px;
  width: 10%;
  right: 40px;
  padding: 0px;
  color: #fff;
  text-align: center;
  z-index: 9;
}
.kindergardenimg{

  padding: 15px;
  text-align: center;
  border-radius: 6px 6px 0px 0px;
  background: url('https://webapi.entab.info/api/image/STMST/public/Images/sliderinfobg.png') no-repeat !important; 
}
.kindergarden a img {
  margin-top: -15px;
}
.kindergarden h6 {
  background: #302d2d;
  font-size: 12px;
  line-height: 22px;
  border-radius: 0px 0px 6px 6px;
  text-align: center;
  padding: 8px 5px;
  margin-bottom: 0;
  padding-bottom: 25px;
}








.preadmission{
  position: absolute;
  bottom: 30px;
  width: 10%;
  right: 40px;
  padding: 0px;
  color: #fff;
  text-align: center;
  z-index: 9;
}
.preadmissionimg{
  padding: 15px;
  text-align: center;
  border-radius: 6px 6px 0px 0px;
  background: url('https://webapi.entab.info/api/image/STMST/public/Images/sliderinfobg.png') no-repeat !important; 
}
.preadmission a img {
  margin-top: -15px;
}
.preadmission h6 {
  background: #302d2d;
  font-size: 13px;
  line-height: 20px;
  border-radius: 0px 0px 6px 6px;
  text-align: center;
  padding: 13px 4px;
  margin-bottom: 0;
  padding-bottom: 25px;
  text-transform: uppercase;
}



.curr-sec .curr-blk {
  position: relative;
  background: #ffffff;
  padding: 30px;
}


.contact .form-space {
  margin-bottom: 30px;
}

.contact .f-control {
  padding: 10px 12px;
}





.slider-info h4 {
  text-transform: uppercase;
  font-size: 18px;
  line-height: 30px;
}
.slider-info-text h5 {
  color: #fcb100;
  text-align: right;
  font-size: 14px;
}
.midsection{  background:linear-gradient(-45deg, rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)) ,  url('https://webapi.entab.info/api/image/STMST/public/Images/midsection.png') no-repeat !important; }
.aboutus {
  width: 35%;
  display: flex;
  margin: -100px auto 0 auto;
  color: #fff;
  flex-direction: column;
  padding: 30px;
  background: linear-gradient(178deg, #2869a5, #103252);
  position: relative;
  z-index: 2;
  border-radius: 5px;
}
.aboutusdesc p {
  line-height: 30px;
  font-size: 16px;
}
.aboutusimg {
  text-align: center;
  margin-bottom: 30px;
}
.aboutusdesc h1 {
  font-size: 25px;
  text-align: center;
  line-height: 35px;
  margin-bottom: 20px;
}
.maintitle img {
  margin-bottom: 20px;
  width: 50px;
}
.newssecbox {
  padding: 20px;
  margin: 10px;
  background: linear-gradient(2deg, #2869a5, #103252);
}
.newssecitem {
  padding: 20px 30px;
  text-align: center;
  margin-bottom: 30px;
}

.newssecitemtitle {
  color: #fff;
  font-size: 20px !important;
  line-height: 30px !important;
  border-bottom: 1px solid #ffffff96;
  padding-bottom: 15px;
}

.newssecitemdesc {
  font-size: 16px;
  line-height: 30px;
  color: #fff;
}
.newssecboxdate h3 {
  color: #ffffff87;
  font-size: 30px;
  line-height: 40px;
  font-weight: bold;
}
.newssecboxitem a{
  display: flex;
  justify-content: flex-end;
}
.maintitle {
  margin-bottom: 35px;
  text-align: center;
}

.maintitle h2 {
  font-size: 25px;
  line-height: 30px;
  color: #255c8f;
  font-weight: bold;
  position: relative;
}
.maintitle h2:before {
  background: #f49b00;
  content: ' ';
  width: 70px;
  top: -7px;
  height: 2px;
  position: absolute;
  margin-left: 0;
  transform: translate(-50%, -50%);
  left: 50%;
}
.newssec {
  margin-top: 50px;
}
.aboutschool {
  padding: 50px 0px;
}
.homeaboutimg {
  border: 20px solid #fff;
  box-shadow: 1px 3px 7px 4px #0000003b;
  margin-top: 30px;
}

.aboutschool button {
  text-align: center;
  display: block;
  margin: -10px auto 0px auto;
  position: relative;
  z-index: 9;
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: inset 1px 1px 1px 1px #00000061;
}
.aboutschool button p {
  margin-bottom: 0;
  font-weight: normal;
  color: #fff;
  font-family: "Be Vietnam Pro", sans-serif;
}
.aboutschool p {
  line-height: 30px;
  font-size: 16px;
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  color: #333;
}
.aboutschool p span {
  color: #2869A5;
  font-weight: bold;
}
.homemsgtitle {
  position: relative;
}

.homemsgtitle .maintitle {
  position: absolute;
  top: 20px;
  left: 30px;
}
.messagebox {
  background: #fff;
  border-radius: 0px 50px 50px 50px;
  box-shadow: 0px 0px 15px 0px #1e1e1e;
}
.homemsg{ padding: 120px 0px 50px 0px;background:linear-gradient(-45deg, rgba(16, 50, 82, .8), rgba(16, 50, 82, .8)) ,  url('https://webapi.entab.info/api/image/STMST/public/Images/homemsgbg.jpg') no-repeat !important; }
.messagetop {
  display: flex;
  justify-content: space-between;
}

.messageboximg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.messageboximg img {
  height: 180px;
  width: 180px;
  margin-top: -50px;
  border-radius: 115px;
  border: 10px solid #fff;
} 
.messagedesc {
  padding: 0 30px 30px 30px;
}
.messageboxtitle h3 {
  font-size: 25px;
  line-height: 35px;
  color: #fff;
  padding-left: 20px;
  margin-bottom: 0;
}
.messageboxtitle {
  background: linear-gradient(178deg, #2869a5, #103252);
  border-radius: 0px 100px 0px 0px;
  display: flex;
  align-items: center;
  padding: 20px 10px 20px 40px;
  margin-top: -50px;
  margin-bottom: 50px;
  width: 405px;
  position:relative;
  margin-left: -35px;
}

.messageboxtitle:after {

  content: '';
  position: absolute;
  display: block;
  bottom: -34px;
  left: 9px;
  transform: translateX(-50%);
  border-style: solid;
  border-width: 3px 30px 20px 20px;
  border-color: transparent transparent orange transparent;
  border-bottom: 33px solid #fff0;
  border-right: 33px solid #062746;

}
.facilities{
  padding: 120px 0px 100px 0px;
  background: linear-gradient(176deg, rgba(255, 255, 255, .9) 10%, rgba(255, 255, 255, .7) 50%, rgba(255, 255, 255, .3) 100%), url('https://webapi.entab.info/api/image/STMST/public/Images/facilites.jpg') no-repeat !important;

}
.facilitiesbox h3 {
  font-size: 14px;
  line-height: 26px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
}

.facilitiesbox {
  margin: 30px auto 20px auto;
  display: block;
  text-align: center;
}

.facilitiesbox img {
  width: 60px !important;
  height: 60px;
  margin: 0 auto;
}
.homenews {
  padding: 50px  0px 100px  0px;
background:linear-gradient(-45deg, rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)) , url('https://webapi.entab.info/api/image/STMST/public/Images/eventbg.jpg') no-repeat !important; }
  
.homenews h2 span {
  display: block;
}
.homenewsboxdesc {
  background: #f5f5f5;
  padding: 15px 0px;
  min-height: 135px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.homenewsboxtitle {
  background:#225a8e;
  z-index: 9;
  position: relative;
  border-radius: 7px;
  margin-top: 6px;
}

.homenewsboxtitle p {
  color: #fff;
  font-size: 18px !important;
  padding: 5px 5px;
  text-align: center;
  margin-bottom: 0;
  line-height: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.homenewsboximg img {
  border-radius: 10px;
  height: 190px;
  width: 100% !important;
  object-fit: contain;
}
.subgalleryimg img {
  height: 240px;
  object-fit: contain;
}
.homenewsboxdesc p {
  font-size: 16px;
  line-height: 28px;
  color: var(--black);
  padding: 0px 10px;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0px;
}
.homenewsboxbottom {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 0px 10px;
}
.homenewsboxbottom span i {
  color: var(--maroon);
}
.homenewsbox {
  margin: 0px 10px;
  padding: 10px;
  background: #f5f5f5;
  box-shadow: 0px 1px 13px 1px #ccc;
}
.homenewsbox {
  margin: 0px 10px 20px 0px;
}
.homenewsboxbottom span i {
  color: #1a4872;
}
.flwidth {
  width: calc(90% - 40px);
}
.homenews p {
  margin-bottom: 0;
}
.hometopper{ padding: 120px 0px 50px 0px;background:linear-gradient(-45deg, rgba(16, 50, 82, .8), rgba(16, 50, 82, .8)) ,  url('https://webapi.entab.info/api/image/STMST/public/Images/tooperbg.jpg') no-repeat !important; }

.toppermaintitle h2 {
    font-size: 25px;
    line-height: 30px;
    color: #ffff;
    font-weight: bold;
}
.topperbox {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-top: 30px;
}

.topperdiv {
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topperimages img {
  border-radius: 60px;
  width: 120px !important;
  background: #fff;
  border: 2px solid #fff;
  height: 120px;
  margin-bottom: 20px;
}

.topperimages {
  position: relative;
}

.topperdesc p {
  text-align: center;
  color: #fff;
  font-family: 'Domine';
  margin-bottom: 4px;
}
.tooperpicicn {
  margin-top: -37px;
}
.topperdesc p span {
  display: block;
  color:#fff !important;
}
.toppermaintitle {
  text-align: center;
}

.infrastructuretitle h3 {
  font-size: 18px !important;
  padding: 15px 5px 5px 5px;
  text-align: center;
  margin-bottom: 0; 
  display: -webkit-box;
  -webkit-line-clamp:1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 30px;
  
}

.infrastructureimg {
  position: relative;
}

.infrastructurereadmore {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px 10px;
  background: #FFD45199;
  opacity: 0;
  transition: all 0.4s ease-in;
}
.infrastructurereadmore a {
  color: #333333;
  font-weight: bold;
  font-size: 17px;
  line-height: 27px;
}

.infrastructuredesc {
  padding: 10px 20px;
}
.infrastructuredesc p {
  font-size: 16px;
  line-height: 28px;
  color: var(--black);
  padding: 0px 10px;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp:2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  
}
.infrastructure {
  padding: 10px;
  height: 350px;
  border-radius: 4px;
  box-shadow: 0 0 14px 4px rgb(0 0 0 / 8%);
  margin: 10px 6px 25px 6px;
  background: #fff;
  position: relative;
  z-index: 9;
}
.infrastructureimg img {
  height: 180px;
  object-fit: contain;
  width: 100%;
  background: #f1f1f1;
}
.homegallery {
  padding: 120px 0px 100px 0px;
  background: linear-gradient(-45deg, rgba(255, 255, 255, .1), rgba(255, 255, 255, .1)) , url('https://webapi.entab.info/api/image/STMST/public/Images/gallerybg.jpg') no-repeat !important;
}
.footerform{
  background:  url('https://webapi.entab.info/api/image/STMST/public/Images/footerform.jpg') no-repeat !important;
}
.formbox {
  padding: 20px 40px;
  background: #fff;
  box-shadow:0 -7px 16px 0px rgb(0 0 0 / 10%);
position: relative;
  margin-bottom: 60px;
  border-radius: 4px;
}
.formbox:before {
  position: absolute;
  content: ' ';
  background: #ef8f00;
  width: 7px;
  height: 160px;
  left: 0;
}
.formbox h2 {
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  line-height: 30px;
}
.formmainbox {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
}

.formboxleft {
  width: 49%;
  margin: 0px 5px;
}

.formboxright {
  width: 49%;
  margin: 0px 5px;
}
.form-group {
  margin-bottom: 25px;
}

.form-group label {
  margin-bottom: 10px;
}

.form-group input::placeholder, .form-group textarea::placeholder {
  color: #ccc;
}
.formbox p{margin-bottom: 0;}
.formbox textarea {
  height: 230px;
  resize: none;
}

.getin {
  display: flex;
  align-items: center;
}

.getin h3 {
  background: linear-gradient(45deg, #ffffff80, transparent);
  padding: 15px 20px;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 15px;
  color: #666;
  text-align: center;
  width: 100%;
}
input[type="submit"] {
  transition: all 0.3s ease-in;
  padding: 10px 30px;
  border: 0px solid #ccc;
  background: linear-gradient(45deg, #5d1010, #be973e);
  color: #fff;
  border-radius: 4px;
  margin-top: 10px;
}
input[type="submit"]:hover {
  background: #1b4b78;
}
.form-group input.form-control:focus , .form-group textarea.form-control:focus {
  box-shadow: none;
  border: 1px solid #ccc;
}

.footer iframe{
  height:200px
}
.app-cont {
  width: 70%;
}

.app-cont img {
  width: 130px !important;
}

.phone-img img {
  width: 100% !important;
}

.phone-img {
  width: 30%;
}
.footer .d-flex {
  align-items: flex-end;
}
.footer .link{
  color: #000;
  padding: 5px 23px;
  border-radius: 8px;
  background: linear-gradient(173deg, #ffb800, #ef8f00);
  margin-top: 16px;
  width: max-content;
  max-width: 100%;
}
.copyright p {
  text-align: center;
  margin-bottom: 0;
  padding: 5px 0;
  font-size: 12px;
}
.footer {
  color: #fff;
  background: linear-gradient(178deg, #2869a5, #103252);
  padding: 70px 0px 0px 0px;
}
.quick-link li {
  list-style: none;
  font-size: 16px;
  line-height: 30px;
}

.quick-link {
  margin-top: 75px;
  display: flex;
  flex-wrap: wrap;
}

.cont-dtl {
  margin-top: 30px;
  margin-bottom: 30px;
}

.cont-dtl p {
  font-size: 14px;
  line-height: 22px;
}
.quick-link ul {
  width: 50%;
  padding-left: 10px;
}
.cont-dtl h3 {
  padding-left: 10px;
  font-size: 23px;
  line-height: 30px;
  color: #fff;
  position: relative;
  font-weight: normal;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.cont-dtl h3:before{
  background: #f49b00;
  content: ' ';
  width: 70px;
  bottom: 0px;
  height: 2px;
  position: absolute;
  margin-left: 0;
  left: 0;
}
.divider {
  border-right: 1px solid #cccccc1c;
}
.copyright {
  border-top: 2px solid #cccc;
  padding: 10px;
}

.copyright p a {
  color: #fff;
}


.owl-carousel .owl-item img{
  width: auto;
}

.messageboximg p span {
    display: block;
    font-weight: normal;
}

.messageboximg p {
    font-size: 21px;
    text-align: right;
    font-weight: bold;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled {
  display: block;
}
.owl-nav .owl-next, .owl-nav .owl-prev {
  position: absolute;
  top: 48%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.owl-nav .owl-next {
  right: 0;
  display: flex;
  margin-right: 2%;
  font-size: 18px !important;
}
.owl-nav .owl-prev{
  left: 0;
  display: flex;
  margin-left: 2%;
  font-size:18px !important;
}
.owl-carousel .owl-nav button.owl-prev {
margin-left: -60px;
}
.owl-carousel .owl-nav button.owl-next {
margin-right: -60px;
}
.owl-carousel .owl-nav button.owl-prev,.owl-carousel .owl-nav button.owl-next {
background: #21588c;
padding: 7px 15px 11px 12px !important;
border-radius: 12px;
color: #fff;
box-shadow: inset 0 0 20px #123759, 0px 0px 1px 0 #000000;
}
.owl-theme .owl-nav [class*='owl-']:hover {
background: #21588c;
color: #FFF;
text-decoration: none;

transition: all 0.5s ease-in;
}
.homemsg .owl-nav , .hometopper .owl-nav .owl-prev , .homenews .owl-nav .owl-prev{
display: none;
} 
.calbg{padding: 80px 0px 80px 0px;background-color: #F6F9FEAB;}
.event-cal {
    border-radius: 10px;
}
.header-text h3 {
    color: #fff;
    margin-left: 0px;
    font-size: 16px;
    /* background: #c95404; */
    text-align: center;
    padding: 6px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px #c10005 solid;
}

.header-text h5 {
    font-size: 16px;
    text-align: center;
    color: white;
    margin: 10px;
}
.calendar-header {
    background: #c33337;
    height: 100%;
    color: #fff !important;
    font-family: 'Roboto',sans-serif;
    font-weight: 300;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.calendar-header i {
    font-size: 47px !important;
    padding: 0px 10px;
}
.calendar-wrapper.z-depth-2 {
  box-shadow: -5px 3px 6px 3px #ccc;
  background: #fff;
}
.calendar-content {
  padding: 20px;
  position: relative;
    background-color: #fff;
}
.calendar-content:after{
  position: absolute;
  right: 0;
  width: 70px;
  transform: translate(95%, 0%);
  height: 100%;
  top: 0;
  background: #f39900;
  content: ' 2024';
  z-index: 1;
  writing-mode: vertical-lr;
  text-orientation: upright;
  font-size: 50px;
  font-family: 'Domine';
  padding-top: 12px;

}
#table-header .col {
  padding: 5px;
  background: #e7e3e3f1 !important;
  margin: 2px;
}
.calendar-footer h4 {
    margin: 20px auto;
}
.calendar-footer:after {
  content: ' ';
  position: absolute;
  background: url('https://webapi.entab.info/api/image/STMST/public/Images/calnder.PNG') no-repeat bottom !important;  
  
  height: 100%;
  width: 70px;
  right: 0;
  top: 0;
  z-index: 1;
  transform: translate(95%, 0%);
} 


.calendar-footer p {
    line-height: 19px;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    margin-bottom: 4px;
    text-align: left;
}

.blue {
    color: #080e42;
    font-weight: bold;
}

#table-body .col {
  padding: 10px 30px;
  text-align: center;
  flex-grow: 1;
}

div#table-body {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.sidebar-title {
    padding: 10px;
    display: none;
}


.event-mark {
    width: 5px;
    height: 5px;
    background-color: teal;
    border-radius: 100px;
    position: absolute;
    left: 46%;
    top: 70%;
}

.calendar-footer {
    font-family: 'Roboto',sans-serif;
    font-weight: 300;
    text-align: center;
    background: #F6F9FEAB;
    position: relative;
    padding: 10px 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.calendar-footer h4 {
    margin: 20px auto;
}

.calendar-footer p {
    line-height: 19px;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    margin-bottom: 4px;
}

.eventlist {
    overflow-y: scroll;
    height: 70px;
}
.sidebar-wrapper {
    color: #fff;
    background-color: #5a649c !important;
    font-family: 'Roboto',sans-serif;
    font-weight: 300;
    padding: 0 0 20px;
}

.eventCard {
    background-color: #fff;
    color: #000;
    padding: 12px 24px;
    border-bottom: 1px solid #E5E5E5;
    white-space: nowrap;
    position: relative;
    animation: slideInDown .5s;
}

.eventCard-header {
    font-weight: 700;
}

.eventCard-description {
    color: grey;
}

.eventCard-mark-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 60px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 25%, white 100%);
}

.eventCard-mark {
    width: 8px;
    height: 8px;
    background-color: #b39ddb;
    border-radius: 100px;
    position: absolute;
    left: 50%;
    top: 45%;
}

#table-body .row .col {
    padding: .6rem;
    width: 14%;
}

#table-body .row {
    margin-bottom: 0;
}

#calendar-table {
    text-align: center;
}

.prev-button {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 15%;
    color: #fff !important;
}

.prev-button i {
    font-size: 4em;
}

.next-button {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 15%;
    color: #fff !important;
}

.next-button i {
    font-size: 4em;
}
.eventlist {
    overflow-y: scroll;
    height: 70px;
}
.red{color: #c33337;}
.eventlist p span{color: #c33337;font-weight:bold;}
.eventlist p span.black{ color:black;font-weight:bold;}

div#table-header {
    font-size: 14px;
    font-weight: bold;
    color: black;
}

.event-cal button {
  padding: 0;
  margin: 0;
  height: 0;
}

.event-cal button i {
  padding: 0;
}
.event-cal button {
  padding: 0;
  margin: 0;
  height: 0;
}

.event-cal button i {
  padding: 0;
}

h5#todayDayName {
  display: none;
}

.header-text h3 {
  padding: 0;
  font-size: 38px;
  background: #fff !important;
  color: #225a8e;
  text-align: left;
  border-bottom: 0px solid #ccc;
  margin-bottom: 0;
}

.event-cal .next-button {
  color: #fff!important;
  background: #225a8e;
  padding: 20px;
  top: 0px;
  border-radius: 0;
}

.event-cal .next-button i {
  position: relative;
  top: -30px;
}
.event-cal {
  width: 80%;
}
.header-text h3 {
  padding-top: 20px;
  padding-left: 11px;
  font-size: 30px;
}
.event-cal .prev-button {
  display: none;
}
#table-body .col {
  padding: 5px 22px;
  text-align: left;
}
.event-cal .next-button i {
  position: relative;
  top: -38px;
}
.eventlist {
  display: flex;
  flex-wrap: wrap;
}

.eventlist p {
  background: #225a8e52;display: none;
  padding: 5px 20px;
  border-radius: 15px;
  margin: 4px 5px;
  font-size: 12px;
  width: max-content;
}
.event-cal .next-button {
  color: #fff!important;
  background: #225a8e;
  padding: 30px 10px;
  top: 0px;
  right: -65px;
      border-radius: 0px 0px 0px 0px;
  box-shadow: inset 0 0 20px #123759, 0px 0px 1px 0 #000000;
}
@media all and (min-width: 992px) {

  .navbar .nav-item .dropdown-menu {
       display: none;
   }
  .navbar .nav-item:hover .dropdown-menu {
       display: block;
       margin: 0;
       padding: 0;
   }

  .navbar .nav-item .dropdown-menu {
       margin-top: 0;
   }
} 


.innerslide {
  margin-top: -35px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(360deg, #1c1b3b8f 100%, #1c1b3b91 100%, #3333338c 100%),url('https://webapi.entab.info/api/image/STMST/public/Images/innersld.png') no-repeat center center;
  background-size: cover;
  height: 255px;
  padding-bottom: 50px;
}
.innersec {
  min-height: 400px;
  padding: 100px 10px;
}
.innersec p {
  line-height: 34px;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  color: #4e4e4e;
}
.innersec h1 {
  font-size: 35px;
  position: relative;
  padding-left: 70px;
  background: -webkit-linear-gradient(#2b82d3, #bb9934);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 45px;
  padding-bottom: 20px;
}

.innersec h1:after {
  position: absolute;
  content:  ' ';
  width: 60px;
  height: 3px;
  background: -webkit-linear-gradient(#2b82d3, #bb9934);
  top: 20px;
  bottom: 0;
  left: 0;
}
.aboutinnerimg {
  padding: 4px;
  outline: 2px solid #fff8f8;
  outline-offset: -15px;
  border-radius: 11px;
}

.breadcrumb li:before {
  position: absolute;
  content: ' \F138 ';
  width: 100%;
  height: 100%;
  font-family: bootstrap-icons;
  left: -15px;
}
.breadcrumb li:first-child:before {
  content: "\f404";
  background: #f49c00;
  width: 30px;
  border-radius: 20px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -25px;
  top: -3px;
}
.breadcrumb li a {
  color: #f49c00;
  position: relative;
}
.breadcrumb li {
  padding: 0px 15px 0px 15px;
  color: #fff;
  position: relative;
  margin-left: 7px;
  font-size: 14px;
}
ul.breadcrumb {
  margin-bottom: 0;
}
p.name span {
  color: #ff3633;
  display: block;
  font-weight: 400;
}
p.name {
  font-size: 16px;
  font-weight: 700;
}
p.stream {
  font-size: 10px;
  font-weight: normal;
}

.maintable thead th {
  border: 1px solid #ccc;
  background: #537493;
  color: #fff;
  vertical-align: middle;
}

.maintable tbody td {
  border: 1px solid #ccc;
}

.maintable tbody tr:nth-child(odd) {
  background: #f1f1f185;
}

.maintable tbody tr:nth-child(even) {
  background: #e8f4ff;
}
.tablebg {
  background: #e2e8ed;
  padding: 20px 10px;
  border: 1px solid #cccccc36;
  margin-bottom: 20px;
}

.tablebg .maintable tbody tr:nth-child(odd) {
  background: #fff;
}

.innersec h6 {
  color: #144572;
  font-size: 19px;
  line-height: 34px;
}
.innersec b {
  color: #e45b28;
  font-weight: bold;
}
.msgbox {
  float: right;
  margin-bottom: 20px;
  margin-left: 30px;
  position: relative;
  width: 40%;
}

.msgbox img {
  border: 0px solid #bdbdbd;
  padding: 10px;
  position: relative;
  box-shadow: 0px 2px 10px 1px #ccc;
  border-radius: 5px;
}
.maintable b {
  color: #000;
  font-weight: 500;
}
ul.mainlist li {
  line-height: 30px;
  font-size: 16px;
  list-style: none;
  position: relative;
  color: #4e4e4e;
  margin-bottom: 10px;
}
ul.mainlist li:before {
  content: "\2726";
  font-size: 1.6em;
  color: #f49c00;
  position: absolute;
  left: -30px;
  top: 0;
}
.qrcodelink img {
  margin: 0 auto 30px auto;
  display: block;
  border-radius: 3px;
  padding: 2px;
  border-style: double;
  border-color: #ccc;
  width: 50%;
}

.qrcodelink p {
  text-align: center;
  font-weight: bold;
  color: #e45b28;
}
.quick-link a:hover {
  color: #e45b28;
}
.slider-info .carousel-indicators [data-bs-target] {
  background: #f6a101;
  border-radius: 30px;
  height: 7px;
  border-top: 0;
  border-bottom: 0;
  width: 7px;
}


.video-gallery {
  padding: 20px;
  box-shadow: 0px 0px 4px 0px #03030330;
  margin-bottom: 20px;
  border-radius: 4px;
  background: #c7def3;
}
.video-gallery a {
  display: flex;
  color: inherit;
  align-items: center;
}
.video-gallery a i {
 font-size: 40px;
 color: #ad0807;
 margin-right: 10px;
 display: flex;
}
.video-gallery a h3 {
  position: static;
  font-size: 16px;
  margin-bottom: 0;
  line-height: 24px;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 100;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 0;
  padding-left: 0;
  background: transparent;
  -webkit-text-fill-color: unset;
  font-family: 'Poppins';
}
.video-gallery p {
  font-size: 12px;
  margin-bottom: 0;
}
.video-gallery a h3:after {
  position: static;
}
.video-gallery a img {
  width: 52px;
  margin-right: 10px;
}
.chooseyear { margin-bottom: 40px;}
#chooseddlYear {
  border: unset;
  color: #000;
  height: 30px;
  padding: 0px 0px 0px 4px;
  background: unset;
  font-weight: bold;
  margin-bottom: 15px;
  letter-spacing: 1px;
  width: 6.5em;
  font-size: 15px;
  float: right;
  margin-top: 0;
  border-bottom: 1px solid grey;
}
.chooseyear select {
  margin: 0px;
}
select#chooseddlYear:focus-visible {
  outline: none;
}

.row.tabs-dynamic {
  background: #f1f1f1;
  padding: 10px;
  margin: 0px 0px 40px 0px;
}

.row.tabs-dynamic p {
  margin-bottom: 0;
}

.row.tabs-dynamic select {
  width: 100%;
  border: 0px;
  background: transparent;
  padding: 8px 5px;
  border-bottom: 1px solid #b6b6b6;
}

.row.tabs-dynamic input {
  padding: 9px 5px;
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #b6b6b6;
  background: transparent;
}

.count-val p {
  margin-bottom: 0px;
}
.row.tabs-dynamic p a {
  color: #333;
}
.years {
  display: none;
}

.showYear {
  display: flex;
}

.load-more .next {
 width: max-content;
 padding: 5px 10px;
 color: #fff;
 border-radius: 5px;
 margin: auto;
 margin-top: 20px;
 background: #1c1b3b;
}
.myDiv1{display:none;}
.selected_year{display: block;}
.gallery-inner {
  background: #1c1b3b;
  margin-bottom: 30px;
  box-shadow: 7px 6px 12px -4px #ddd;
  /* position: relative; */
  overflow: hidden;
  transition: all 0.5s ease-in;
}
.gallery-blks a {
  color: inherit;
}
.gallery-blks img {
  width: auto;
  max-width: 100%;
  height: 90%;
  margin: auto;
  display: block;
  position: relative;
  box-shadow: 0px 0px 19px 0px #00000054;
  border: 7px solid #ffffff4f;
}
.gallery-title h3::after {
  position: static;
}
.gallery-inner:hover .gallery-content {
  bottom: 0;
}
.gallery-blks {
  height: 230px;
  object-fit: contain;
  position: relative;
  background: #ddd;
  overflow: hidden;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
} 

.gallery-title p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 28px !important;
  font-size: 16px !important;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.gallery-date {
  display: flex;
}

  .gallery-date h6 {
      color: #fff;
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 25px;
      background: #fc097c;
      padding: 5px 10px;
      border-radius: 5px;
      font-family: 'Poppins', sans-serif;
      margin-top: 0;
  }

.gall-num h5 {
  background: transparent;
  color: #000;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 25px;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
}
.galleryCount:nth-child(2n+2) .gallery-inner .gallery-date h6 {
  background: #f9d464;
}

.galleryCount:nth-child(2n+3) .gallery-inner .gallery-date h6 {
  background: #00acee;
}

.galleryCount:nth-child(2n+4) .gallery-inner .gallery-date h6 {
  background: #198754;
}

.innersec .gallery-block a {
  color: inherit;
}
.gallery-content {
  padding: 20px 15px;
  position: relative;
  background: #fff;
  width: 96%;
  margin: -30px auto 10px auto;
}

.innersec .gallery-block img {
  width: auto;
  max-width: 100%;
  height: 90%;
  margin: auto;
  display: block;
  position: relative;
  box-shadow: 0px 0px 19px 0px #00000054;
  border: 7px solid #ffffff4f;
}

.gallery-inner:hover .gallery-content {
  bottom: 0;
}
.innersec .gallery-block {
  height: 230px;
  object-fit: contain;
  position: relative;
  background: #ddd;
  overflow: hidden;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
.subgalleryimg img {box-shadow: 0px 0px 19px 0px #00000054;border: 7px solid #ffffff4f;width: calc(34% - 20px);margin: 0px 0px 10px 10px;}

.subgalleryimg {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.innersec .gallery-block .gallery-title h3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.gallery-title h3 {
  font-size: 18px;
  color: #b78c0b;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  padding: 0px;
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  background: transparent;
}
h4.gallery-title {
  color: #000000bf;
  font-size: 20px;
  line-height: 30px;
  font-family: "Inter", sans-serif;
}
.eventbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #f1f1f1bd;
  box-shadow: 2px 0px 8px 0px #ccc;
  margin-bottom: 20px;
}

.eventboximg {
  width: 40%;
  margin-right: 5px;
  position: relative;
}

.eventboxdesc {
  width: 58%;
}
.eventpgh {
  height: 125px;
  overflow-y: scroll;
  padding-top: 13px;
}

.eventboximg img {
  border: 10px solid #f1f1f1bd;
  height: 165px;
  object-fit: contain;
  background: #fff;
  width: 100%;
}
.eventdate {
  display: flex;
  justify-content: space-between;
  padding: 8px 10px 8px 5px;
}
.eventpgh p {
  font-size: 15px;
  line-height: 30px;
}
.eventdate a {
  border-radius: 5px 3px 6px 0px;
  color: #fff;
  padding: 2px 5px;
}
.eventdate span {
  font-size: 13px;
  line-height: 23px;
  color: #1c1b3b;
}
.eventpgh h5 {
  color: #9e790c;
  line-height: 30px;
  font-size: 18px;
  font-family: "Inter", sans-serif;
}
.eventdate span i {
  color: #1c1b3b;
  position: relative;
  bottom: 3px;
}
.eventpgh::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.eventpgh::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #F5F5F5;
}
.eventattch {
  background: #f49a00;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0px 5px 4px 5px;
  border-radius: 0px 0px 10px 10px;
  color: #fff;
}

.newscountbox {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.news-block {
  width: 100%;
  position: relative;
  padding: 20px 10px 15px;
  background-color: #ffffffcf;
}

.newsCount .attachment {
  position: absolute;
  right: 0;
  bottom: 0px;
  background: #101130;
  padding: 8px 10px;
  border-radius: 3px 10px 3px 10px;
}

.newsCount .attachment a {
  color: #fff;
}

.newsCount {
  width: calc(51% - 35px);
  position: relative;
  padding: 15px;
  display: flex;
  background-color: #f2f4f7;
  justify-content: center;
  margin: 10px 10px;
}

.newsCount:before {
  position: absolute;
  left: 0;
  top: 0%;
  height: 0;
  width: 100%;
  content: "";
  background: linear-gradient(to right, #ff4200 0%,rgba(69,74,180,1) 50%,#ffc600 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f14b59', endColorstr='#ffa200',GradientType=1 );
  transition: all 500ms ease;
}
.newsCount:hover:before{
height: 100%;
  top: 0;
}
.news-head h4 {
  padding: 10px 20px;
  font-size: 15px;
  background: #eea201;
  width: max-content;
  border-radius: 5px;
  color: #fff;
}
.news-head h3 {
  padding-left: 0;
  font-size: 16px;
  position: static;
  background: transparent;
  color: #000;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  padding-bottom: 0;
  font-family: 'Poppins';
}

.news-head h3:after {
  position: static;
}
.popup {
  padding-right: 0 !important;
}
.popup .carousel-control-prev-icon { background: url('https://webapi.entab.info/api/image/STMST/public/Images/lefticn.png') no-repeat !important; }
.popup .carousel-control-next-icon { background: url('https://webapi.entab.info/api/image/STMST/public/Images/righticn.png') no-repeat !important; }
.popup .carousel-control-next, .popup .carousel-control-prev { width: auto; }
.popup .carousel-control-next { right: -15px; }
.popup .modal-dialog {  transform: translateY(-100px); }
 .modal-header { background: #0a4685; padding: 13px 0 0 }
.popup .modal-dialog img { width: 100%; object-fit: contain; }
 .modal-body h1 {padding-bottom: 0; text-align: center; font-size: 17px; color: #000; overflow: hidden; font-weight: 600; text-transform: capitalize; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; margin-bottom: 10px; line-height: 28px; }
.modal-dialog .modal-header .btn-close { color: #fff; padding-right: 31px }
 .modalcard .card-body { padding: 10px }
 .modalcard .card-body p { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; line-height: 35px; text-align: center; padding-top: 10px; line-height: 26px; }
 .modalcard { border: none }
.popup .modal-content { padding: 0 0 10px }
 button.btn-close:focus { box-shadow: none }
 a.btn.btn-primary.btn-sm {background: var(--maroon);border:solid 1px #205b0e;margin: 0 10px;color:#000;}
 a.btn.btn-primary.btn-sm:focus { box-shadow: none; border: 1px solid #182b3c; }
 button.btn-close {position: absolute;z-index: 999;background-color: #0c2236;right: 0;}
 .btn-close { background: url('https://webapi.entab.info/api/image/STMST/public/Images/closeicn.png') no-repeat; opacity: 1; padding: 10px; background-position: center; border-radius: 0; }
.modal-bottom { display: flex; flex-direction: row; justify-content: center; align-items: center; margin-top: 30px; }


.infrastructurebox img{
  box-shadow: 0px 0px 19px 0px #00000054;
  border: 7px solid #fff;
  width: calc(34% - 20px);
  margin:0px 0px 30px 10px;
}
.infrastructuretab .nav-pills {
  width: 300px;
  margin-right: 10px;
}
.infrastructuretab .nav-pills .nav-link {
  width: 100%;
  background: #9ecbf3;
  color: #06447d;
  border-radius: 2px;
  margin-bottom: 7px;
  box-shadow: -2px 3px 0px 1px #235e95;
}
.infrastructuretab .tab-content {
  width: 100%;
}
.infrastructuretab{
   display: flex;
}
.erp_sec .guideheading {
  text-align: left
}

.erp_sec h4 {
  text-align: center;
  line-height: 40px;
  font-size: 17px;
  margin-bottom: 0;
  margin-top: 15px;
  font-weight: 700
}

.erp_sec a {
  color: #000
}

.assistance {
  background:rgb(224 240 255);
  padding: 13px;
  margin-top: 20px;
  border: solid 1px #ddd
}
#myImg, #myImg1, #myImg2 {
  width: 100%;
  border: 5px solid rgba(158, 158, 158, 0.1);
  background: #eaeaea;
  cursor: pointer;
  transition: 0.3s; }

#myImg:hover {
  opacity: 0.7; }

.erp_sec p {
  line-height: 40px !important;
  margin-bottom: 0;
  font-size: 16px;
}
h4.imgheading {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 18px;
}

.contactdiv { width: 65%;}
.enquiryformimg{width: 35%;background: #d2eaff ;padding-left: 20px;padding-right: 20px;} 
.enquiryform {flex-wrap: wrap;display: flex;flex-direction: row;justify-content: center;width: 100%;background: #fff;} 
.enquiryformsec {/* width: 50%; *//* background: #f1f1f1; *//* padding: 30px; *//* display: flex; *//* flex-direction: column; *//* justify-content: center; *//* align-items: center; */}
.locationbox {margin-top: 65px;} .locationicon { padding: 0px 7px; width: 45px; } 
.locationicon i {color: #246097;font-size: 30px;} 
.locationdiv {display: flex;margin-bottom: 20px;border-bottom: 1px dashed #215b8f;}
.locationdetails { margin-left: 5px; width: 90%; margin-bottom: 10px; } 
.locationdiv h5 {font-weight: 600;color: #246097;font-size: 19px;text-transform: capitalize;}
.locationdiv p a { color: #000; font-size: 16px; margin-bottom: 0; }
.locationdiv:last-child { border-bottom: 0px dashed #215b8f;}
.topperbtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.innersec .topperssec {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.innersec .topperdiv {
  margin: 8px 10px 10px 10px;
  width: calc(20% - 20px);
  background: #e5f2ff;
  box-shadow: 0px 0px 5px 0px #ccc;
  position: relative;
}

.innersec .topperdivimg {
  width: 115px;
  height: 115px;
  margin: 20px auto;
}

.innersec .topperdivimg img {
      width: 100%;
      height: 100%;
      border-radius: 130px;
      border: 2px solid #02749e;
      background: #fff;
      object-fit: contain;
  }

.innersec .topperdivdesc {
  background:#dceeff;
  padding: 10px 5px;
  width: 100%;

}

 .innersec .topperdivdesc p {
      margin-bottom: 0;
      text-align: center;
      font-size: 16px;
  }
.innersec .topperdivdesc p span {
  display: block;
  font-size: 15px;
}
.innersec .topperper {
  position: absolute;
  top: -2px;
  background: #245f96;
  width: max-content;
  border-radius: 0px 0px 10px 1px;
  left: 0;
}

.innersec .topperper p {
  margin-bottom: 0;
  padding: 5px 10px;
  color: #fff;
}
 a.btn.btn-primary.btn-sm.urlTitle {
  background: linear-gradient(173deg, #ffb800, #ef8f00);
  color: #fff;
  border: 1px solid#fff;
  text-transform: uppercase;
}
.modal-content .item{
  position: relative;
}
@keyframes blinker {
  0%{
      background: #fcb100;
  }
  100%{
      background: #18456f;
  }
}

@media(max-width: 1660px){
.headerbottom { 
  width:70%;
}
}
@media(max-width: 1620px){
  .headerbottom {
      padding-left: 10px;
  }
  }
@media (max-width: 1600px)  
{

  .kindergarden {
    width: 14%;
    right: 20px;
  }
  .slider-info {
    bottom: 70px;
    width: 26%;
  }
  .headerbottom {
    margin-right: 40px;
}

  .midheader {
    padding-right: 40px;
  }
  .flwidth {
    width: calc(100% - 26px);
}

}

@media (max-width: 1524px)  
{

.navbar-nav li {
  padding-right: 13px;
  font-size: 13px;
}

}

@media (max-width: 1430px){
.navbar-nav li {
    padding-right: 13px;
    font-size: 12px;
}
}
@media(max-width: 1400px){
  .popup .modal-dialog {
    min-width: 84%;
  }
  .innersec .topperdiv {
        width: calc(25% - 20px);
    }
  .navbar-expand-lg {
    justify-content: center;
    text-align: center;
    width: 100%;
}
  .navbar-expand-lg .navbar-collapse {
    width: 100%;
    justify-content: center;
}
  .midheader {
    padding-top: 10px;
    padding-bottom: 10px;
}
  .eventlist {
    height: 36px;
}
  .event-cal {
    width: 88%;
}
  .quick-link li {
    font-size: 15px;
  }
  .newssecitem {
    padding: 20px 15px;
  }
  .newssecbox {
    padding: 10px;
  }
  .highlights {
    width: 60%;
  }
  .topheaderleft, .topheaderright {
    width: 20%;
}
  .slider-info {
    bottom: 100px;
  }
  .aboutus {
    width: 65%;
  }
  .slider-info {
    width:30%;
    margin-bottom: 10px;
  }
  .headerbottom {
    width: 73%;
    padding-top: 10px;
    padding-bottom: 10px;
}
 
  .footer .link { 
    font-size: 13px;
  }
  .midheaderleft {
    width: 35%;
}


  }
  
@media(max-width: 1324){
   
  .quick-link ul {
      width: 51%;
      margin-bottom: 0;
  }
  
  }
  @media (max-width: 1260px) {
  .headerbottom {
    padding-left: 0px;
}
.headerbottom {
  width: 75%;
}
.navbar-nav li {
  padding-right: 10px;
}
.midheader {
  padding-right: 20px;
}
.headerbottom {
  margin-right: 20px;
}
}
@media (max-width: 1200px) {
   .innersec .topperdiv {
        width: calc(33% - 20px);
    }
  .contactdiv {
    width: 60%;
}
   .enquiryformimg {
    width: 40%;
}
  .infrastructurebox img { 
    width: calc(51% - 20px);
  }
  .qrcodelink p {
    font-size: 15px;
    line-height: 25px;
}
  .midheaderleft img {
    width: 410px;
}
.qrcodelink img {
  width: 75%;
}
.midheaderleft {
  width: 50%;
}
  .midheader {
    justify-content: center;
  }
  .owl-nav .owl-next, .owl-nav .owl-prev {
    position: static;
    top: 48%;
    transform: translateY(-50%);
    -webkit-transform: translateY(0%);
}
  .eventlist {
    height: 78px;
}
  .kindergarden {
    width: 19%;
    right: 0;
    top: 0;
}
  .headerbottom {
    border-radius: 0;
    padding-top: 0px;
    padding-bottom: 0px;
}
  .messagebox {
    margin-top: 100px;
    margin-left: 100px;
  }
  .slider-sec {
    margin-top: 0;
}
  .headerbottom {
    width: 100%;

}
  .footer .d-flex { 
    margin-top: 0;
  }
  
}

@media(max-width:991px){
  .contact-items .right-item {
    padding-left: 15px;
    border-left: transparent;
}
.contact-items .left-item .item {
  margin-top: 14px;
  margin-bottom: 14px;
}

   .innersec .topperdiv {
        width: calc(50% - 20px);
    }
  .contactdiv {
    width: 100%;
}
 .enquiryformimg {
    width: 100%;
}
  .popup .modal-dialog {
    min-width: 95%;
  }
  .subgalleryimg img {
    width: calc(51% - 20px);
  }
  .qrcodelink p {
    font-size: 11px;
    line-height: 25px;
}
  .navbar-expand-lg {
    justify-content: flex-start;
    text-align: left;
  }
  .msgbox {
    width: 50%;
}
  .eventlist {
    height: 39px;
}
  .event-cal {
    width: 44%;
    margin: 20px auto 40px auto;
}
  .owl-carousel .owl-nav button.owl-next {
    margin-right: -37px;
} 
  .owl-carousel .owl-nav button.owl-prev {
    margin-left: -37px;
}
 .toppertry {
    width: 50%;
    margin-bottom: 40px;
}
  .headerbottom .navbar-toggler{
    margin-left: auto;
  }
  .slider-info {
    width: 42%;
    padding: 20px 20px;
  }
  .slider-info h4 {
    font-size: 12px;
}
  .headerbottom {
    padding: 0;
    display: block;
}
 
  
  .headerbottom ul {
    flex-direction: column;
}
.headerbottom li {
    padding: 10px;
}
.headerbottom li:after {
    content: '   ';
}
  .midheader {
    justify-content: center;
}
  .messagebox {
    margin-left: 50px;
}
  .messageboxtitle {
    width: 315px;
  }
  .topheaderright {
    display: flex;
}

  .app-cont img {
    width: 120px!important;
  }
  .footer .link {
    text-align: center;
    font-size: 13px;
  }
  }
  @media(max-width: 767px){
   
    .infrastructuretab {
      flex-wrap: wrap;
  }
    .infrastructuretab .nav-pills {
      width: 100%;
      margin-right:  0px;
      margin-bottom: 20px;
  }
    .qrcodelink img {
      margin-bottom: 20px;
  }
    .qrcodelink p {
      font-size: 15px;
      line-height: 25px;
  }
    .msgbox {
      margin-top: 20px;
      width: 100%;
      float: none;
      margin-left: 0px;
  }
    .midheadermid {
      margin-top: 16px;
      margin-bottom: 20px;
  }
    .topheaderright {
      width: 100%;
      justify-content: center;
  }
  .midheader {
    padding-right: 0;
}
    .event-cal {
      width: 65%;
  }
    .messageboxtitle {
      width: 100%;
  }
  .messageboxtitle {
    margin-left: 0;
}
.messageboxtitle {
  border-radius: 0;
}
.messageboxtitle:after{
  display: none;
}
.messagebox {
  border-radius: 0px 0px 15px 15px;
}
.messagebox {
  margin-left: 0;
}

    .kindergarden {
      width: 24%;
    }
    .slider-info {
        width: 95%;
        background: #4d5052 !important;
        position: static;
        margin: 20px auto;
        border-radius: 3px;
  }
    .topheaderleft, .topheaderright {
      width: 100%;
  }
  .highlights {
    width: 100%;
}
    .messageboximg {
      width: 100%;
  }

  .messagetop {
    flex-wrap: wrap;
}
  .aboutus {
    width: 96%;
    margin: 15px auto 0 auto;
}
}
@media(max-width: 567px){
  
   .innersec .topperdiv {
        width: calc(50% - 20px);
    }
  .subgalleryimg img {
    width: 100%;
    margin: 0px 0px 10px 0px;
}
  .midheaderleft {
    margin-bottom: 20px;
}
.midheaderleft {
  width: 97%;
}
.midheader {
  padding-left: 0;
}
.midheadermid {
  flex-wrap: wrap;
  margin-bottom: 20px;
}
  .event-cal {
    width: 84%;
    margin-left: 0px;
    margin-bottom: 30px;
}
  .owl-carousel .owl-nav button.owl-next {
    margin-right: -37px;
} 
  .owl-carousel .owl-nav button.owl-prev {
    margin-left: -37px;
}
  .messageboximg p {
    order: 2;
    text-align: center;
}
  .messageboximg {
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
}
.kindergarden {
  z-index: 9;
  width: 38%;
  position: fixed;
  bottom: 0;
  right: 0;
  top: 410px;
}
.formmainbox {
  flex-wrap: wrap;
}
.formboxleft {
  width: 100%;
}
.formboxright {
  width: 100%;
}
}
  @media(max-width: 400px){
    .app-cont, .phone-img {
      width: 100%;
  }
  }
  .mainimg {
    border: 4px double #235c92;
    border-radius: 5px;
    height: 240px;
    width: 100%;
    margin-bottom: 10px;
}